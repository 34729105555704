

.main {
  height: 93%;
  .secondary_menu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80vh;
    // display: none;
    .navigate_menu {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 2rem;
      width: 40%;
      // height: 100%;
      padding-top: 3rem;
      .hold_menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 1rem;

        .menu {
          outline: none;
          border: none;
          background: none;
          background: white;
          box-shadow: $low_shadow;
          padding: 2rem;
          border-radius: 10px;
          margin-bottom: 0.5rem;
          cursor: pointer;

          &:hover,
          &:active,
          &:focus {
            background: #100d2e;
          }

          &:hover,
          &:active,
          &:focus i {
            color: #01b1ff;
          }

          i {
            font-size: 3vw;
          }
        }

        span {
          color: rgb(66, 66, 66);
          font-weight: 500;
        }
      }
    }
  }

  .table_container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .top {
      background: #ddd;
      height: 10%;
      width: 100%;
      position: relative;
      .top_section-two {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 2rem;

        .page_title {
          font-family: 'Roboto', sans-serif;
          font-weight: normal;
          padding-top: 1rem;
          // padding-left: 2rem;
          display: flex;

          .map {
            @include format_btn(0.7rem, black);
            margin: 0 0.5rem;

            &:hover {
              color: rgb(46, 46, 253);
            }
          }
        }

        .search_container {
          @include new_search(18rem);
          color: black;
        }

        .advanced {
          @include format_btn(0.8vw, grey);
          @include filter_criteria-btn;
        }

        .print {
          background: none;
          outline: none;
          border: none;
          font-size: 1vw;
          font-weight: 500;
          margin-right: 2rem;
          cursor: pointer;
          border: rgb(240, 240, 240) 1px solid;
          padding: 0.5rem 1rem;
          border-radius: 7px;

          &:hover {
            color: rgb(59, 59, 59);
          }
        }

        .upload-button {
          @include format_btn(0.9vw, white);
          @include upload_btn;
        }
      }

      .advanced_search-container {
        position: absolute;
        width: 60%;
        right: 0.5rem;
        background: white;
        padding: 1rem;
        box-shadow: 0 0 10px rgb(190, 190, 190);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        z-index: 100;
        select {
          outline: none;
          background: none;
          border: none;
          color: black;
          font-size: 1.1vw;
          width: 25%;
          border-bottom: 1px solid $filter-bg;
          margin-right: 2rem;
          margin-bottom: 1rem;
        }
        .filter_all {
          @include format_btn(0.7rem, lightgrey);
          font-weight: 600;
          @include filter_btn;
          margin-bottom: 1rem;
        }
      }
    }

    .table {
      width: 90%;
      height: 75vh;
      padding: 1rem;
      box-sizing: border-box;
      overflow-y: auto;

      .head_row {
        width: 100%;
        display: grid;
        grid-template-columns: 0.3fr 1fr 0.5fr 1fr 1fr 0.3fr;
        background: rgb(206, 206, 206);
        padding: 0.5rem;

        .data {
          font-size: 0.9rem;
        }
      }

      .table_body-container {
        height: 90%;
        width: 100%;
        overflow-y: scroll;

        .body_row {
          width: 100%;
          display: grid;
          grid-template-columns: 0.3fr 1fr 0.5fr 1fr 1fr 0.3fr;
          padding: 0.5rem;
          box-sizing: border-box;
          &:not(:last-of-type) {
            border-bottom: 1px solid rgb(163, 163, 163);
          }

          .data {
            font-size: 0.9rem;
            font-weight: normal;
          }
        }
      }
    }
  }

  .id_container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .undergraduate_id {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .front_side {
        border: 1px solid lightgrey;
        width: 660px;
        height: 1020px;

        .top_logo-section {
          height: 10%;
          padding: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #29166f;
          color: white;
          border-bottom: 5px solid rgb(196, 1, 1);

          .logo {
            height: 100%;
            margin-right: 1rem;
          }

          .school_name-container {
            text-align: center;
            line-height: 1;
            .main_name {
              font-family: sans-serif;
              text-transform: uppercase;
              font-weight: 900;
              font-size: 2.5rem;
            }

            .sub_name {
              font-size: 1.435rem;
              font-family: sans-serif;
              margin: 0.2rem 0;
              font-weight: 900;
              text-transform: uppercase;
            }

            .address {
              font-size: 1.4rem;
              font-weight: normal;
              font-family: 'Times New Roman', Times, serif;
              text-transform: capitalize;
            }
          }
        }

        .center_part {
          position: relative;
          width: 100%;
          .top_img {
            width: 35rem;
            height: 15rem;
            object-fit: cover;
            object-position: center;
            position: absolute;
            right: 0;
            top: 0;
            z-index: -1;
          }

          .picture_holder {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            width: 100%;

            .passport_container {
              width: 25rem;
              height: 28rem;
              background: #29166f;
              padding: 0.2rem;
              margin-top: 3rem;
              position: relative;
              .passport {
                height: 98%;
                width: 98%;
                border-top: 2px solid #98c8ec;
                border-right: 5px solid #98c8ec;
                position: absolute;
                left: 0;
                object-fit: cover;
                border-top-right-radius: 50px;
              }
            }

            .id_type {
              font-size: 1.5rem;
              transform: rotate(-90deg);
              flex-shrink: 0;
              position: absolute;
              left: 0rem;
              top: 17rem;
              text-transform: uppercase;
              color: rgb(196, 1, 1);
              font-weight: 900;
              font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
            }

            .blood_type {
              font-size: 1.4rem;
              transform: rotate(-90deg);
              flex-shrink: 0;
              position: absolute;
              right: 1rem;
              top: 21rem;
              text-transform: uppercase;
              color: rgb(196, 1, 1);
              font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
              font-weight: bold;
            }
          }
        }

        .student_details {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 17.5rem;

          .bottom_img {
            width: 10rem;
            height: 9rem;
            object-fit: cover;
            object-position: top;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: -1;
          }

          .student_name {
            font-weight: 500;
            font-family: sans-serif;
            font-size: 2.3rem;
            text-transform: uppercase;
            margin: 0.2rem 0;
            line-height: 1;
            margin-top: 1rem;
            margin-bottom: 1rem;
          }



          .faculty {
            font-size: 1.9rem;
            font-family: sans-serif;
            width: 75%;
            text-align: left;
            font-weight: 600;
            line-height: 1;
            display: flex;

            span {
              font-weight: 800;
              font-size: 2.1rem;
              font-family: Verdana;
            }
          }

          .department {
            font-size: 1.8rem;
            font-family: sans-serif;
            width: 75%;
            text-align: left;
            display: flex;
            line-height: 1;
            font-weight: 600;
            margin-bottom: 1rem;
            span {
              font-size: 1.8rem;
              font-weight: 600;
              font-family: Verdana;
            }
          }

          .mat_no {
            font-size: 1.9rem;
            font-family: sans-serif;
            width: 70%;
            text-align: left;
            font-weight: 600;
          }
        }

        .final {
          width: 100%;
          display: flex;
          align-items: center;
          height: 6rem;
          border-top: 4px solid #98c8ec;
          .name_bg {
            clip-path: polygon(0 0, 91% 0, 100% 100%, 0% 100%);
            background: #98c8ec;
            width: 80%;
            height: 100%;
          }

          .last_name {
            background: #29166f;
            clip-path: polygon(0 0, 91% 0, 97% 100%, 0% 100%);
            width: 100%;
            height: 100%;
            color: white;
            font-family: sans-serif;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 4rem;
            text-transform: uppercase;
            font-weight: 900;
          }

          .sig {
            font-size: 0.6rem;
          }
        }
      }

      .back_side {
        border: 1px solid lightgrey;
        width: 660px;
        height: 1020px;

        .declaration {
          font-family: 'sans-serif, Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana;
          text-align: center;
          padding: 0.5rem;
          border-bottom: 5px solid grey;
          height: 47%;
          display: flex;
          flex-direction: column;
          align-items: center;
          .to_whom {
            font-size: 1.9rem;
            font-family: arial;
            // font-weight: 600;
            margin-top: 1rem;
            margin-bottom: 1rem;
          }

          .sub {
            font-size: 1.2rem;
            margin-bottom: 2rem;
            width: 90%;
          }

          .school {
            font-size: 2rem;
            margin-bottom: 1.5rem;
            font-family: sans-serif;
            font-weight: 900;
            .school_sub {
              display: block;
              font-size: 1.1rem;
              font-weight: 900;
              font-family: arial;
            }
          }

          .information {
            margin-bottom: 0.5rem;
            font-size: 1.2rem;
            width: 90%;
          }

          .date_sign {
            display: flex;
            justify-content: space-around;
            align-items: flex-end;
            margin-top: 2rem;
            width: 100%;

            .date_container {
              font-size: 0.6rem;

              .issued,
              .expiry {
                margin-top: 0.5rem;
                font-size: 1rem;
                font-weight: 700;
                color: rgb(22, 22, 22);
                span {
                  margin-left: 0.5rem;
                }
              }
            }

            .sign {
              width: 30%;
              .main_signature {
                font-size: 1.5rem;
                font-weight: normal;
                border-top: 1px solid grey;
                font-family: arial;
                padding-top: 0.2rem;
              }
            }
          }
        }

        .serial {
          border-top: 5px solid lightgrey;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 57%;

          .barcode {
            width: 60%;
          }

          &_no {
            transform: rotate(90deg);
            position: absolute;
            right: 2rem;
            top: 10rem;
            font-family: sans-serif;
            font-size: 1.5rem;
            font-weight: 700;
          }
        }
      }
    }

    .post_graduate {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .front_side {
        border: 1px solid lightgrey;
        width: 660px;
        height: 1020px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .top_logo-section {
          height: 10%;
          padding: 2rem;
          display: flex;
          justify-content: space-around;
          align-items: center;
          color: #29166f;

          .logo {
            height: 7rem;
          }

          .school_name-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            line-height: 1;
            width: 100%;
            .main_name {
              font-family: arial;
              text-transform: uppercase;
              font-weight: 900;
              font-size: 2.9rem;
            }

            .sub_name {
              font-size: 1.99rem;
              font-family: sans-serif;
              color: #007474;
              margin: 0.2rem 0;
              text-transform: uppercase;
              font-weight: bold;
            }

            .address {
              width: 88%;
              text-align: center;
              font-size: 1.99rem;
              text-transform: uppercase;
              font-weight: bold;
              font-family: arial;
              color: rgb(1, 1, 31);
              word-spacing: 0.5rem;
            }
          }
        }

        .id_type {
          background: #29166f;
          width: fit-content;
          width: -moz-fit-content;
          height: 2rem;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 1rem;
          .type {
            border-top: white 5px solid;
            border-bottom: white 5px solid;
            font-family: arial;
            font-size: 2.3rem;
            line-height: 1;
            margin-bottom: 0;
            color: white;
          }
        }

        .picture_holder {
          display: flex;
          align-items: flex-end;
          justify-content: center;
          width: 100%;

          img {
            width: 19rem;
            height: 23rem;
            margin-top: 1rem;
            object-fit: cover;
            border: 6px solid #0377c5;
          }
        }

        .student_details {
          position: relative;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-row-gap: 2rem;
          align-items: center;
          height: 5.4rem;
          padding: 0.5rem;
          margin-top: 2rem;

          .info {
            text-transform: uppercase;
            text-align: center;
            font-family: sans-serif;
            font-size: 1.5rem;
            font-weight: bold;
            color: maroon;

            .data {
              display: block;
              color: black;
              font-size: 1.5rem;
            }
          }

          .sign {
            text-align: center;
            border-top: 1px solid grey;
            width: 80%;
            font-size: 1.3rem;
            font-family: sans-serif;
            font-weight: bold;
            margin-left: 1rem;
            margin-top: 1rem;
          }
        }

        .final {
          width: 100%;
          display: flex;
          align-items: center;
          height: 3rem;
          border-top: 4px solid #98c8ec;
          .name_bg {
            clip-path: polygon(0 0, 91% 0, 100% 100%, 0% 100%);
            background: #98c8ec;
            width: 80%;
            height: 100%;
          }

          .last_name {
            background: #29166f;
            clip-path: polygon(0 0, 91% 0, 97% 100%, 0% 100%);
            width: 100%;
            height: 100%;
            color: white;
            font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .sig {
            font-size: 0.6rem;
          }
        }
      }

      .back_side {
        border: 1px solid lightgrey;
        width: 660px;
        height: 1020px;

        .declaration {
          font-family: Arial, Helvetica, sans-serif;
          text-align: center;
          padding: 0.5rem;
          display: flex;
          align-items: center;
          flex-direction: column;
          padding-top: 3rem;

          .sub {
            font-size: 1.8rem;
            margin-bottom: 4rem;
            font-weight: 600;
            line-height: 1.2;
            text-transform: capitalize;
            width: 80%;
          }

          .school {
            font-size: 3rem;
            font-weight: bold;
            margin-bottom: 0.5rem;
            display: flex;
            flex-direction: column;
            align-items: center;

            span {
              display: block;
              font-weight: 500;
            }

            .pg {
              font-size: 1.7rem;
              text-transform: uppercase;
              font-weight: 600;
            }

            .school_sub {
              font-size: 1.7rem;
              text-transform: uppercase;
              width: 70%;
              font-weight: 600;
            }
          }

          .information {
            margin-bottom: 0.5rem;
            font-size: 1.6rem;
            padding: 0 3rem;
            font-weight: 600;
          }

          .sign {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 5rem;
            margin-bottom: 1rem;
            .dean_sign {
              border-top: 4px solid rgb(49, 49, 49);
              font-size: 1.8rem;
              font-weight: normal;
              width: fit-content;
              width: -moz-fit-content;
              text-transform: uppercase;
              font-weight: 600;
              font-family: Arial, Helvetica, sans-serif;
            }
          }
        }

        .serial {
          display: flex;
          justify-content: center;
          margin-top: 3rem;
          img {
            width: 20rem;
          }
        }
      }
    }
  }
}

.small_text{
  text-transform: uppercase;
  font-size: 1.7rem !important;
  font-family: sans-serif;
  margin: 0.2rem 0;
  line-height: 1;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
 .larger_text{
  text-transform: uppercase;
  font-size: 1.4rem !important;
  font-family: sans-serif;
  margin: 0.2rem 0;
  line-height: 1;
  margin-top: 1rem;
  margin-bottom: 1rem;

}
