.main_navigation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 93%;
  overflow-y: scroll;
  width:100%;

  .welcome_note{
    padding-top: 10rem;
    margin-bottom: 1rem;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.6vw;
    color: $color-primary-dark;

    .sub_note{
      font-size: 1.2vw;
      color:grey;
    }
  }

  .main_menu {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    width: 70%;

    .hold_menu {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1rem;

      .menu {
        outline: none;
        border: none;
        background: none;
        background: white;
        box-shadow: $low_shadow;
        padding: 2rem;
        border-radius: 10px;
        margin-bottom: 0.5rem;
        cursor: pointer;

        &:hover,
        &:active,
        &:focus {
          background: #100d2e;
        }

        &:hover,
        &:active,
        &:focus i {
          color: #01b1ff;
        }

        i {
          font-size: 3vw;
        }
      }

      span {
        color: rgb(66, 66, 66);
        font-weight: 500;
      }
    }
  }
}
