.certificate_signin {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e0f1f8;

  @include respond(phone) {
    display: block;
  }

  .image_holder {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;

    @include respond(phone) {
      display: none;
    }
    img {
      width: 70%;
    }
  }

  &-form {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #01001b;

    @include respond(phone) {
      width: 100%;
      background-image: linear-gradient(to bottom, #01001bea, #01001bea), url(~assets/images/RS10595_014176.jpg);
      background-size: cover;
      background-position: center;
    }

    .cert_signin-form {
      .title {
        color: rgb(3, 205, 212);
        font-family: sans-serif;
        font-size: 2.5vw;
        font-weight: normal;
        margin-bottom: 4rem;

        @include respond(phone) {
          font-size: 7.5vw;
        }
      }

      .cert_form-input {
        @include form(20rem);
        margin-top: 2rem;
      }

      .signin_btn {
        margin-top: 1rem;
        @include submit_btn(20rem);
      }
    }
  }
}


.disable{
  background: rgb(161, 161, 161) !important;
  cursor: not-allowed !important;
  padding:10px !important;
}

.disable_login{
  background: rgb(161, 161, 161) !important;
  cursor: not-allowed !important;
}