.grad_cert-main {
  // margin-top: 7rem;
  .secondary_menu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80vh;
    // display: none;
    .navigate_menu {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 2rem;
      width: 60%;
      // height: 100%;
      padding-top: 3rem;
      .hold_menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 1rem;

        .menu {
          outline: none;
          border: none;
          background: none;
          background: white;
          box-shadow: $low_shadow;
          padding: 2rem;
          border-radius: 10px;
          margin-bottom: 0.5rem;
          cursor: pointer;

          &:hover,
          &:active,
          &:focus {
            background: #100d2e;
          }

          &:hover,
          &:active,
          &:focus i {
            color: #01b1ff;
          }

          i {
            font-size: 3vw;
          }
        }

        span {
          color: rgb(66, 66, 66);
          font-weight: 500;
          font-size: .9vw;
        }
      }
    }
  }

  .top_section {
    background: #ddd;
    // display: none;

    .top_section-two {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding:.5rem 1rem;
      position:relative;

      .page_title {
        font-family: 'Roboto', sans-serif;
        font-weight: normal;
        padding-top: 1rem;
        // padding-left: 2rem;
        font-size: 1.2vw;

        .main_title{
          display:block;
        }

        .map{
          @include format_btn(.8vw, grey);
          box-shadow:unset;


          &:first-child{
            margin-left: 1rem;
          }
          &:not(:first-child){
            color: blue;
          }


        }

      }



      .filter_btns {
        display:flex;
        width:80%;
        justify-content: flex-end;
        .Filter {
          @include format_btn(0.9vw, white);
          @include filter_btn;
          padding:.5rem;
          height: fit-content;

          &:not(:first-child){
            margin-left: .5rem;
          }
        }

        .filter_criterium {
          border:none;
          background:none;
          border-bottom: 2px solid rgb(63, 63, 63);
          padding: 0.5rem;
          outline: none;
          width:50%;
          // @include filter_criteria-btn;
          font-size: 1.1vw;
        }
      }

      .print {
        background: none;
        outline: none;
        border: none;
        font-size: 1vw;
        font-weight: 500;
        // margin-right: 2rem;
        cursor: pointer;
        border: rgb(255, 255, 255) 2px solid;
        padding: 0.5rem;
        border-radius: 7px;
        color:blue;

        &:hover {
          color: rgb(59, 59, 59);
        }
      }

      .upload-button {
        @include format_btn(0.9vw, white);
        @include upload_btn;
      }
    }
  }




  .add_height {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1rem;
    // display: none;
    max-height: 80vh;
    overflow-y: scroll;

    .student_detail {
      width: 100%;
      display: inherit;
      justify-content: space-between;
      margin: 0.5rem 0;
      padding: 0.5rem;
      background: white;
      border-radius: 10px;
      box-shadow: $high_shadow;

      .info {
        color: grey;
        font-weight: 500;
        font-size: .9vw;
        &:nth-child(2) {
          width: 13%;
        }
        .main_info {
          display: block;
          color: black;
          font-weight: normal;
          font-size: .9vw;
          overflow-wrap: break-word;

        }
      }

      .more {
        background: none;
        outline: none;
        border: none;
        font-size: 0.9vw;
        cursor: pointer;
        height: 25px;
        width: 25px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem;
        color: black;

        &:hover {
          background: rgb(231, 231, 231);

          border-radius: 50px;
          color: black;
        }
      }
    }
  }

  .cert_list {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1rem;
    // display: none;
    max-height: 70vh;
    overflow-y: scroll;

    .student_detail {
      width: 99%;
      display: inherit;
      justify-content: space-between;
      margin: 0.5rem 0;
      padding: 0.5rem 1rem;
      background: white;
      border-radius: 10px;
      box-shadow: $high_shadow;

      .info {
        color: grey;
        font-weight: 500;
        font-size: .85vw;
        &:nth-child(2) {
          width: 13%;
        }
        .main_info {
          display: block;
          color: black;
          font-weight: normal;
        }
      }

      .more {
        background: none;
        outline: none;
        border: none;
        font-size: 0.9vw;
        cursor: pointer;
        height: 25px;
        width: 25px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem;
        color: black;

        &:hover {
          background: rgb(231, 231, 231);

          border-radius: 50px;
          color: black;
        }
      }
    }
  }

  .batch_cert {
    padding: 0 .5rem;
    .certificate_table {
      width: 100%;
      margin: 0.5rem 0;
      padding: 0.5rem;
      background: white;
      border-radius: 10px;
      box-shadow: $high_shadow;

      .tab_top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding:1rem .5rem;
        width: 100%;
        background:#eee;

        p{
          padding-bottom:0;
          margin-bottom:0;
        }
      }

      .the_table {
        width: 100%;

        .hold_info {
          display: grid;
          grid-template-columns:.2fr .8fr 0.5fr 0.7fr 0.7fr 1fr 1fr 1fr 0.5fr 0.4fr .5fr 0.1fr .2fr;
          align-items: center;
          width: 100%;
          color: grey;
          margin-bottom: 0.5rem;
          border-bottom: 1px solid rgb(197, 197, 197);
          font-size: .9vw;
          overflow-wrap: break-word;



          &:first-child {
            color: black;
          }

          .info{
            overflow-wrap: break-word;
            overflow: hidden;
          }
          // &:nth-child(odd) {
          //   background: rgb(219, 219, 219);
          // }
        }
      }
    }

    // .hold_info {
    //   display: grid;
    //   grid-template-columns: 0.27fr 1fr 0.5fr 0.7fr 0.7fr 1fr 0.3fr 0.4fr 0.4fr 0.2fr;

    //   .info {
    //     color: grey;
    //     font-weight: 500;
    //     &:nth-child(2) {
    //       width: 100%;
    //     }
    //     .main_info {
    //       display: block;
    //       color: black;
    //       font-weight: normal;
    //     }
    //   }
    // }
  }
}


.printed{
  border-left:2px solid green;
}

.certificate_printout {
  width: 100%;
  height:100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  // position: relative;


  // display: none;


  .certificate_information {
    width: 100%;
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .name {
      width: 100%;
      font-size: 5vw;
      text-align: center;
      font-family: nimbus;
      font-weight: normal;
      line-height: 35px;
      margin-bottom: 1.5rem;
    }

    .address {
      width: 100%;
      font-size: 3vw;
      text-align: center;
      font-family: nimbus;
      // margin-bottom: 1rem;
      font-weight: normal;
      line-height: 0px;
    }

    .layer_2 {
      width: 90%;
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;
      height:20vh;

      .reg_no-1 {
        position: absolute;
        bottom: 50.5%;
        left: 2.9%;
        font-size: 1.5vw;
        font-weight: 600;
      }

      // img {
      //   width: 15%;
      //   margin-top: 2rem;
      // }

      // .reg_no-2 {
      //   position: absolute;
      //   right: 50px;
      //   top: 50px;
      //   font-family: sans-serif;
      //   font-weight: 600;
      //   font-size: 1.6vw;
      // }

      .certify {
        font-family: old_english;
        font-size: 3vw;
      }
    }

    .student_name{
      font-family: flo;
      font-weight: 500;
      font-size: 5.5vw;
      line-height: 1.5;
      margin-top: 2rem;

    }

    .message {
      margin-top: 1rem;
      font-family: Georgia, Times, 'Times New Roman', serif;
      font-size: 3vw;
      width: 100%;
      text-align: center;
      box-sizing: border-box;
      font-weight:600;
      span{
        display: block;
      }
    }

    .degree {
      text-align: center;
      margin-top: 2rem;
      margin-bottom: 1rem;
      width:100%;
      .programme {
        font-size: 4.5vw;
        font-weight: 800;
        // text-transform: capitalize;
        margin-bottom: 1rem;
      }

      .phd_programme {
        font-family: old_english;
        font-size: 5.5vw;
        font-weight: 800;
        // text-transform: capitalize;
        margin-bottom: 1rem;
      }
      .programme_2 {
        font-size: 4.5vw;
        font-weight: 800;
        // text-transform: capitalize;
        margin-bottom: 1rem;
      }

      .degree_info_1 {
        font-size: 3.5vw;
        text-transform: uppercase;
        // margin-bottom: 1rem;
      font-weight: 800;
      }
      .degree_info {
        font-size: 4.5vw;
        text-transform: uppercase;
        margin-bottom: 1rem;
      font-weight: 800;
      }
      .degree_info {
        font-size: 5vw;
        text-transform: uppercase;
        margin-bottom: 1rem;
      font-weight: 800;
      }

      .link {
        font-family: Georgia, Times, 'Times New Roman', serif;
        font-size: 3vw;
        font-weight: bold;
      }

      .department {
        font-size: 5vw;
        text-transform: uppercase;
        margin-top: 1rem;
        font-weight: 800;

      }
      .department_2 {
        font-size: 4.6vw;
        text-transform: uppercase;
        margin-top: 1rem;
        font-weight: 800;

      }
      .department_3 {
        font-size: 4.1vw;
        text-transform: uppercase;
        margin-top: 1rem;
        font-weight: 800;

      }
      .department_test {
        font-size: 3.5vw;
        text-transform: uppercase;
        margin-top: 1rem;
        font-weight: 800;

      }

         .department_4 {
        font-size: 3.1vw;
        text-transform: uppercase;
        margin-top: 1rem;
        font-weight: 800;

      }
    }

    .last_part {
      display: flex;
      justify-content: space-between;
      width: 90%;
      align-items: center;
      // margin-top: 2rem;
      padding:0 2rem;

      // img {
      //   width: 20%;
      //   object-fit: contain;
      // }

      .left {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        // padding-right: 0;
        width:57%;
        height:100%;
        padding-top: .95rem;
        padding-right:.3rem;


        .left_two{
          display: flex;
          justify-content: space-between;
          margin-top: 1rem;

          img {
            width: 30%;
            height:7rem;
            object-fit: contain;
          }
        }

        .avatar{
          height: 104px;
          width: 92.8px;
          object-fit: cover;
          box-sizing: border-box;
          margin-top: 50px;
          margin-bottom: 10px;
          // margin-right: .3rem;
        }

      }

      .signatories {
        width: 35%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        .vc,
        .reg,
        .date {
          text-align: center;
          // margin-bottom: 2rem;
          font-family: Georgia, Times, 'Times New Roman', serif;
          font-size: 4vw;
          width:100%;
          position: absolute;
        }

        .vc{
          margin-bottom: 0;
          top:-10rem;
          left:15%;
        }

        .reg{
          // margin: 4rem 0;
          bottom:-4rem;
          padding-bottom: .5rem;
          left:13%;
          box-sizing: border-box;
        }

        .date{
          bottom:-10rem;
          left:15%;
          padding-bottom: 1.6rem;
          box-sizing: border-box;
          font-weight: 500;
        }
      }


    }
  }


}

.serial_number-gen{
  position:absolute;
  right:0;
  bottom:-1rem;
  font-size: 8px;
}

// pop_up=================================edit popup=================edit popup
.hold_popup{
  .title{
    font-family: sans-serif;
    font-size: 2vw;
    text-align: center;
  }

  form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
  }

  .edit_input{
    @include form(30rem);
    border-color:#373737;

    label{
      color:grey;
    }


    input{
      outline:none;
      color:black;
    }
  }
}



.hold_submit{
  padding-top: .5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  .submit{
    @include format_btn(.9vw, white);
    @include affirm;
  }
}


.hold_upload-btn{
  text-align: center;
  .default_primary-button{
    @include format_btn(.9vw, white);
    @include affirm;
  }
}


.alter_size{
  font-size: 1vw;
}


.batch_id{
  font-size: .9vw;
}

.sort{
  @include format_btn(.85vw, black);
  @include filter_criteria-btn;
  width:10rem;
  padding:.5rem .7rem;
}


.sort_list{
  position:absolute;
  width:35%;
  top:4rem;
  right:1rem;
  background:white;
  display:flex;
  padding:1rem;
  box-shadow: $high_shadow;
  z-index: 10000;

  .the_input{
    label{
      font-size: 1vw;
    }
    .filter_criterium{
      width:fit-content !important;
    }

    &:first-child{
      margin-right: 2rem;
    }
  }
}
