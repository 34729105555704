* {
  margin: 0;
  padding: 0;
  // box-sizing: border-box;
}

body {
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  font-family: 'Oswald', sans-serif;
  position: relative;
  // overflow-x: hidden;
}

.ball_big,
.ball_small {
  background-image: linear-gradient(to bottom, #f9c003, #ff0000);
  filter: blur(5rem);
  opacity: 0.6;
  position: fixed;
  z-index: -1;
  border-radius: 100px;
}

.ball_big {
  height: 40%;
  width: 20%;
  top: 20%;
  right: 5%;
}

.ball_small {
  height: 20%;
  width: 8%;
  bottom: 5%;
  left: 15%;
  filter: blur(2rem);
}

@font-face {
  font-family: old_english;
  src: url(../../app/css/OLD.ttf);
}

@font-face {
  font-family: nimbus;
  src: url(../../app/css/Nimbus.ttf);
}
@font-face {
  font-family: flo;
  src: url(../../app/css/Florens.ttf);
}


// @font-face {
//   font-family: old_english;
//   src: url(../OLD.ttf);
// }
