$red: #f90706;
$orange: #f84d03;
$color-text: #f96028;

$purple-light: #78088d;
$purple-dark: #3a0591;

$color-blue: #0a4dfb;
$color-white: #fff;

$body_dark: #080a19;

$body-grey: #e4e4e4;
$dark-grey: rgb(179, 179, 179);
$darker-grey: rgb(51, 51, 51);

// COLORS
$color-primary: #55c57a;
$color-primary-light: #7ed56f;
$color-primary-dark: #28b485;

$color-secondary-light: #ffb900;
$color-secondary-dark: #ff7730;

$color-tertiary-light: #2998ff;
$color-tertiary-dark: #5643fa;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;

$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;
$filter-bg: rgb(81, 81, 112);

$color-white: #fff;
$color-black: #000;

$aqua: #01b1ff;
$navy: #100d2e;

$low_shadow: 0px 3px 6px rgba(37, 37, 37, 0.171);
$high_shadow: 0px 0px 25px rgba(37, 37, 37, 0.171);
$color-navy:#040024;
$color-navy-light:#0d0287;