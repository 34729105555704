.top_nav {
  height: 7%;
  width: 100%;
  background: white;
  box-shadow: $high_shadow;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // position: fixed;
  // top: 0;
  // left: 0;
  // z-index: 1000;

  .logo {
    width: 40%;
    display: flex;
    align-items: center;
    .icofont-navigation-menu {
      font-size: 1.6vw;
      margin-right: 3rem;
      cursor: pointer;

      &:hover {
        color: grey;
      }
    }

    .app_logo {
      width: 10%;
      height: 90%;
    }
  }

  .user_img {
    width: 3%;
    height: 80%;
    border-radius: 50px;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
    margin-right: 3rem;
  }
}
